import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { IPageWillMountHandler, IPropsStore, PageFeatureConfigSymbol, Props } from '@wix/thunderbolt-symbols'
import { ExpandableMenuPageConfig } from './types'
import { name } from './symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

const updateComponentsProps = (menusIds: Array<string>, currentPrimaryPageHref: string, propsStore: IPropsStore) => {
	const updatedProps = menusIds.reduce(
		(updatedObject, menuId) => ({
			...updatedObject,
			...{
				[menuId]: {
					currentPrimaryPageHref,
				},
			},
		}),
		{}
	)

	propsStore.update(updatedProps)
}

const expandableMenuFactory = (
	pageFeatureConfig: ExpandableMenuPageConfig,
	propsStore: IPropsStore,
	urlHistoryManager: IUrlHistoryManager
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			updateComponentsProps(pageFeatureConfig.componentsIds, urlHistoryManager.getRelativeUrl(), propsStore)
		},
	}
}

export const ExpandableMenu = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, UrlHistoryManagerSymbol],
	expandableMenuFactory
)
